.timeline-body {
    position: relative;
    background-color: var(--timeline-bg);
    border-radius: 0 $rounded-borders $rounded-borders 0;
    padding: 5px 0;
    margin-bottom: 30px;

    &::after {
        // Left Border
        content: "";
        width: 4px;
        height: 100%;
        background-color: $primary;
        position: absolute;
        left: -4px;
        top: 0;
    }

    .timeline-item {
        position: relative;

        &::after {
            // Circle
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 4px solid $primary;
            background-color: var(--main-bg);
            position: absolute;
            left: -12px;
            top: 8px;
            z-index: 10;
        }

        .content {
            margin: 40px;
            padding-bottom: 20px;
            border-bottom: 1px dashed $dark;

            .date {
                margin-top: -5px;
                margin-bottom: 15px;
                color: #6c757d;
            }
        }
    }
}
