.progress {
    margin-bottom: 35px;
    position: relative;
    overflow: hidden;

    > p {
      margin: 0;
      font-size: 18px;
    }

    &::before {
      width: 100%;
      height: 5px;
      content: "";
      display: block;
      position: absolute;
      background: $light;
      bottom: 0;
    }
  }

  .progress-bar {
    width: 100%;
    height: 5px;
    background: var(--progress-bar-bg);
    display: block;
    position: relative;

    span {
      position: absolute;
      border-top: 5px solid inherit;
      top: -30px;
      font-size: 18px;
      padding: 3px 0;
      font-weight: 500;
      right: 0;
    }
  }

  .progress-bar.progress-bar-secondary {
    background: $secondary;
  }

  .progress-bar.progress-bar-success {
    background: $success;
  }

  .progress-bar.progress-bar-info {
    background: $info;
  }

  .progress-bar.progress-bar-warning {
    background: $warning;
  }

  .progress-bar.progress-bar-danger {
    background: $danger;
  }

  .progress-bar.progress-bar-light {
    background: $light;
  }

  .progress-bar.progress-bar-dark {
    background: $dark;
  }

  @for $i from 0 through 100 {
    .progress-#{$i} {
      width: calc($i / 100);
    }
  }

  .progress {
    background-color: var(--progress-bar-bg);
  }
